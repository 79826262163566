.process {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.process-text p {
  font-family: Roboto Flex;
  font-size: 16px;
  font-weight: 500;
}

.process-item img {
  width: 152px;
  border-radius: 6px;
  margin-right: 32px;
}

.process-item {
  height: 160px;
  justify-content: center;
  align-items: start;
  font-size: 16px;
  display: flex;
}

.process-content {
  margin-top: 80px;
}

.process-col-1, .process-col-2 {
  flex-direction: column;
  gap: 100px;
  display: flex;
}

@media only screen and (max-width: 480px) {
  .process .block-title {
    max-width: 510px;
  }

  .process-content {
    flex-direction: column;
    gap: 50px;
    display: flex;
  }

  .process-col-1, .process-col-2 {
    gap: 50px;
  }

  .process-text {
    max-width: 279px;
    position: relative;
  }

  .process-number {
    position: absolute;
    top: -60px;
  }

  .process-item {
    flex-direction: column;
    align-items: center;
    gap: 70px;
    padding: 0 24px;
    height: unset !important;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .process .block-title {
    max-width: 510px;
  }

  .process-content {
    flex-direction: column;
    gap: 100px;
    display: flex;
  }

  .process-text {
    max-width: 279px;
    position: relative;
  }

  .process-number {
    position: absolute;
    top: -60px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .process .block-title {
    width: 710px;
  }

  .process-content {
    flex-direction: column;
    gap: 100px;
    display: flex;
  }

  .process-text {
    max-width: 279px;
    position: relative;
  }

  .process-number {
    position: absolute;
    top: -60px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  .process .block-title {
    width: 820px;
  }

  .process-content {
    gap: 50px;
    display: flex;
  }

  .process-text {
    max-width: 279px;
    position: relative;
  }

  .process-number {
    position: absolute;
    top: -60px;
  }
}

@media only screen and (min-width: 1201px) {
  .process .block-title {
    width: 900px;
  }

  .process-content {
    gap: 100px;
    display: flex;
  }

  .process-text {
    max-width: 279px;
    position: relative;
  }

  .process-number {
    position: absolute;
    top: -60px;
  }
}

/*# sourceMappingURL=index.28b6cabf.css.map */
