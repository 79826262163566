.process {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.process-text p {
    font-family: 'Roboto Flex';
    font-weight: 500;
    font-size: 16px;
}

.process-item img {
    width: 152px;
    border-radius: 6px;
    margin-right: 32px;
}

.process-item {
    display: flex;
    align-items: start;
    justify-content: center;
    font-size:16px;
    height: 160px;
}

.process-content {
    margin-top: 80px;
}

.process-col-1, .process-col-2 {
    gap: 100px;
    display: flex;
    flex-direction: column;
}


/* Mobile */
@media only screen and (max-width: 480px) {
    .process .block-title{
        max-width: 510px;
    }
    .process-content {
        display: flex;
        flex-direction: column;
        gap: 50px;
    }

    .process-col-1, .process-col-2 {
        gap: 50px;
    }

    .process-text {
        position: relative;
        max-width: 279px;
    }

    .process-number {
        position: absolute;
        top:-60px;
    }

    .process-item {
        flex-direction: column;
        gap: 70px;
        align-items: center;
        height: unset !important;
        padding: 0px 24px;
    }
}

/* Tablets */
@media only screen and (min-width: 481px) and (max-width:768px) {
    .process .block-title{
        max-width: 510px;
    }
    .process-content {
        display: flex;
        flex-direction: column;
        gap: 100px;
    }

    .process-text {
        position: relative;
        max-width: 279px;
    }

    .process-number {
        position: absolute;
        top:-60px;
    }
}

/* Small screens */
@media only screen and (min-width: 769px) and (max-width:1024px) {
    .process .block-title{
        width: 710px;
    }
    .process-content {
        display: flex;
        flex-direction: column;
        gap: 100px;
    }

    .process-text {
        position: relative;
        max-width: 279px;
    }

    .process-number {
        position: absolute;
        top:-60px;
    }
}

/* Large screens */
@media only screen and (min-width: 1025px) and (max-width:1200px) {
    .process .block-title{
        width: 820px;
    }
    .process-content {
        display: flex;
        gap: 50px;
    }

    .process-text {
        position: relative;
        max-width: 279px;
    }

    .process-number {
        position: absolute;
        top:-60px;
    }
}

/* Extra large screens */
@media only screen and (min-width: 1201px) {
    .process .block-title{
        width: 900px;
    }
    .process-content {
        display: flex;
        gap: 100px;
    }

    .process-text {
        position: relative;
        max-width: 279px;
    }

    .process-number {
        position: absolute;
        top:-60px;
    }
}